@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap');

* {
    box-sizing: border-box;
}

html {
    --bg: #f5f7f9;
    --light-gray: #dad9e8;
    --gray: #555;
    --yellow: #ffbe0b;
    --orange: #fb5607;
    --red: #ff006e;
    --purple: #8338ec;
    --blue: #3a86ff;
}

body {
    margin: 0;
    font-family: 'IBM Plex Mono', monospace, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg);
    background-image: radial-gradient(#dad9e8 5%, transparent 0);
    background-size: 30px 30px;
}

code {
    color: var(--gray);
    font-size: 18px;
}

h1 {
    text-align: center;
    font-size: 22px;
    letter-spacing: 8px;
    line-height: 100%;
    font-weight: 900;
}

button, input, textarea {
    font: inherit;
    outline: none;
}

button {
    border: none;
    background-color: var(--blue);
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 8px 30px;
    font-size: 22px;
    transition: 200ms;
}

button:hover {
    background-color: #0f6cff;
}

.btn-small {
    font-size: 14px;
    padding: 6px 10px;
}

input, textarea {
    background-color: white;
    border-radius: 8px;
    caret-color: var(--blue);
    padding: 8px 12px;
    font-size: 20px;
    border: 3px solid transparent;
    box-shadow: none;
    outline: none;
    color: #4d4d50;
}

@media (max-width: 767px) {
    input, textarea {
        font-size: 19px;
    }
}

textarea {
    min-height: 97px;
}

input:focus, textarea:focus {
    border-color: #ffbe0b;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
}

input::placeholder, textarea::placeholder {
    color: var(--light-gray);
}

/* @media (prefers-color-scheme: dark) {
  html {
    --bg: #1e1e1e;
    --fg: #efefef;
    --gray: #aaa;
    --shadow: #2a2a2a;
    --light-gray: #444;
  }
  input:focus {
    box-shadow: 0 0 10em rgba(255, 255, 255, 0.02) inset;
  }
} */